@font-face {
  font-family: "Mardato";
  src: url("/public/fonts/Mardoto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/public/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body::-webkit-scrollbar {
  width: 9px;
}

body::-webkit-scrollbar-track {
  background: #f2f2f2;
}

body::-webkit-scrollbar-track:hover {
  background: #f2f2f2;
}

body::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
}

/* Only apply this when the modal is open */
html > .isOpenModal {
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for Safari */
  overflow-y: hidden !important; /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */

  /* Safari-specific prefixes (if necessary) */
  -webkit-overflow-y: hidden !important;
  -webkit-overflow-x: hidden !important;
}

@media screen {
  html {
    overflow-y: auto !important;
  }
}
