/* Block: coming-soon */

/* Element: bubble */
.coming-soon__bubble {
  position: absolute;
  bottom: -50px;
  background-color: #ee5222;
  border-radius: 50%;
  animation: bubble 5s linear infinite;
}

/* Modifiers for bubbles */
.coming-soon__bubble:nth-child(1) {
  width: 20px;
  height: 20px;
  left: 20%;
  animation-delay: 0s;
}

.coming-soon__bubble:nth-child(2) {
  width: 30px;
  height: 30px;
  left: 50%;
  animation-delay: 1s;
}

.coming-soon__bubble:nth-child(3) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-delay: 2s;
}

.coming-soon__bubble:nth-child(4) {
  width: 25px;
  height: 25px;
  left: 30%;
  animation-delay: 3s;
}

.coming-soon__bubble:nth-child(5) {
  width: 35px;
  height: 35px;
  left: 80%;
  animation-delay: 4s;
}

/* Modifier for gradient background with animation */
.coming-soon--animated-gradient {
  height: 100vh;
  background: linear-gradient(120deg, #c2c2d6, #dfe7ec, #b3c0ce, #aab9c3);
  background-size: 300% 300%;
  animation: gradientAnimation 10s ease infinite;
  position: relative;
  overflow: hidden;
}

/* Time block styles */
.coming-soon__time-block {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.coming-soon__time-block:hover {
  transform: scale(1.05);
}

/* Animation keyframes */
@keyframes bubble {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
